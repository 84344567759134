/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var initPhotoSwipeFromDOM;
(function($) {
    initPhotoSwipeFromDOM = function(gallerySelector) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {

            var thumbElements = $(el).find('.photoswipe-item').get(),
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                linkEl = figureEl;//.children[0]; // <a> element

                size = linkEl.getAttribute('data-size');
                if (typeof size !== 'undefined' && size !== null) {
                 size = size.split('x');
                } else {
                    size = '0x0'.split('x');
                }

                // create slide object
                if ($(linkEl).data('type') === 'video') {
                    item = {
                        html: $(linkEl).data('video')
                    };
                }
                else {
                    item = {
                        src: $(linkEl).attr('data-image'),
                        w: parseInt(size[0], 10),
                        h: parseInt(size[1], 10)
                    };
                }

                $caption = $(linkEl).find('.caption');
                if ($caption.length > 0) {
                    item.title = $caption.html();
                } else {
                    item.title = $(linkEl).find('img').attr('title');
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = $(linkEl).find('img').attr('data-image');
                }
                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }
            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        function hasClass(element, cls) {
            return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
        }

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function() {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split('=');
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
            var pswpElement = document.querySelectorAll('.pswp')    [0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {

                closeOnScroll: false,

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName('img')[0]; // find thumbnail
                    var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    var rect;

                    if (typeof thumbnail !== 'undefined' && thumbnail !== null) {
                        rect = thumbnail.getBoundingClientRect();
                    } else {
                        rect = items[index].el.getBoundingClientRect();
                    }

                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                }

            };

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (var j = 0; j < items.length; j++) {
                        if (items[j].pid === index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();

            // connect to player
            var iframe = $('.video-wrapper iframe');

            //todo : check for type
            if (iframe.length) {
              var player = new Vimeo.Player(iframe);
            }

            gallery.listen('beforeChange', function() {
                var currItem = $(gallery.currItem.container);
                $('.pswp__video').removeClass('active');
                var currItemIframe = currItem.find('.pswp__video').addClass('active');
                $('.pswp__video').each(function() {
                    if (!$(this).hasClass('active')) {
                        $(this).attr('src', $(this).attr('src'));
                    }
                });
                if(player) {
                  player.pause();
                }
            });


            gallery.listen('close', function() {
                $('.pswp__video').each(function() {
                    $(this).attr('src', $(this).attr('src'));
                });

                // a bit hacky (return home from single post page)
                if ($('body').hasClass('single') ) {
                    document.location = '/';
                }
                if(player) {
                  player.pause();
                }
            });

        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            var clickedListItem = e.target;

            if (!$(clickedListItem).hasClass('photoswipe-item')) {
                return true;
            } else {
                e.preventDefault();
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.closest(gallerySelector),
                childNodes = $(clickedListItem.closest(gallerySelector)).find('.photoswipe-item').get(),
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;


            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
        }


    };


})(jQuery); // Fully reference jQuery after this point.




